/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

window.Vue = require("vue").default;

import App from "./components/App.vue";
import VueRouter from "vue-router";
import VueAxios from "vue-axios";
import axios from "axios";
import { routes } from "./routes";
import Vue from "vue";
import ElementLoading from "vue-element-loading";
import * as Sentry from "@sentry/vue";
// import VuePlyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'
import VueJWT from "vuejs-jwt";
import VueMobileDetection from "vue-mobile-detection";
import CKEditor from "@ckeditor/ckeditor5-vue2";

const MathEquationsPlugin = {
  install(Vue) {
    Vue.mixin({
      updated() {
        renderMathInElement(document.body);
        // let mathEquations = document.getElementsByClassName("math-tex");
        // for (var i = 0; i < mathEquations.length; i++) {
        //   renderMathInElement(mathEquations[i]);
        // }
      },
      mounted() {
        setTimeout(() => {
          const elements = document.getElementsByTagName("a");
          for (const element of elements) {
            if (element) {
              element.onclick = (e) => {
                e.preventDefault();
                window.open(element.href, "_blank");
              };
            }
          }
        }, 500);
      },
    });
  },
};

Vue.use(VueRouter);


Vue.use(VueAxios, axios);
// Vue.use(VueCarousel);
Vue.use(VueJWT, {
  storage: "localStorage",
});
Vue.use(VueMobileDetection);

Vue.component("VueElementLoading", ElementLoading);

Vue.use(CKEditor);
Vue.use(MathEquationsPlugin);
// Vue.component('VuePlyr', VuePlyr)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
//Vue.component('demo-component', require('./components/DemoComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ?? "LCMS";

  next();
});

const app = new Vue({
  el: "#app",
  router: router,
  render: (h) => h(App),
});

Vue.prototype.$updateElementSize = async () => {
  let _retry = 0;
  let _max_retry = 5;
  const _delay = 500;
  while(_retry <= _max_retry){
    const fixSpaceTp = document.querySelector('.fix-space-tp');
    let coordinates = fixSpaceTp?.getBoundingClientRect()
    console.log(coordinates);
    if(coordinates){
      window.parent.postMessage({ height : coordinates?.bottom || 200 }, process.env.VUE_APP_TP);
      break;
    }
    else{
      _retry += 1;
      await (new Promise(resolve => setTimeout(resolve, _delay)))
    }
  }

};
